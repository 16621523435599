import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { SingleListingPageComponent } from './single-listing-page/single-listing-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { BuyersPageComponent } from './buyers-page/buyers-page.component';
import { SellersPageComponent } from './sellers-page/sellers-page.component';
import { DownloadAppPageComponent } from './download-app-page/download-app-page.component';
import { RealtorsPageComponent } from './realtors-page/realtors-page.component';
import { SearchResultsPageComponent } from './search-results-page/search-results-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
//import { AgentSignupComponent } from './agent-signup/agent-signup.component';
import { AgentSignupConfirmationComponent } from './agent-signup-confirmation/agent-signup-confirmation.component';
import { ScheduleConfirmationPageComponent } from './schedule-confirmation-page/schedule-confirmation-page.component';
import { QrcodeSignupComponent } from './qrcode-signup/qrcode-signup.component';
import { QrcodeRiderComponent } from './qrcode-rider/qrcode-rider.component';
import { QrcodeAgentComponent } from './qrcode-agent/qrcode-agent.component';
import { QrcodeMyagentComponent } from './qrcode-myagent/qrcode-myagent.component';
import { QrcodeAccessConfirmationComponent } from './qrcode-access-confirmation/qrcode-access-confirmation.component';
import { SavedSearchesPageComponent } from './saved-searches-page/saved-searches-page.component';
import { PromoPageComponent } from './promo-page/promo-page.component';
import { PromocertPageComponent } from './promocert-page/promocert-page.component';
import { AgentSignupPageComponent } from './agent-signup-page/agent-signup-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { NerenPageComponent } from './neren-page/neren-page.component';
import { LightningSetupComponent } from './lightning-setup/lightning-setup.component';
import { AppointmentConfirmComponent } from './appointment-confirm/appointment-confirm.component';
import { FeedbackformComponent } from './feedbackform/feedbackform.component';
import { LsEditComponent } from './lightning-setup/ls-edit/ls-edit.component';
import { LsSetupComponent } from './lightning-setup/ls-setup/ls-setup.component';
import { LsHomeComponent } from './lightning-setup/ls-home/ls-home.component';

const routes: Routes = [
  { path: '', component: SearchPageComponent },
  { path: 'search', component: SearchPageComponent },
  { path: 'contact', component: ContactPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'listing/:id', component: SingleListingPageComponent },
  { path: 'about', component: AboutUsPageComponent },
  { path: 'buyer', component: BuyersPageComponent },
  { path: 'sellers', component: SellersPageComponent },
  { path: 'download', component: DownloadAppPageComponent },
  { path: 'real-estate-agent', component: NerenPageComponent },
  { path: 'real-estate-agent/NEREN', component: NerenPageComponent },
  {
    path: 'lightning-setup', component: LightningSetupComponent,
    children: [
      { path: '', component: LsHomeComponent },
      { path: 'edit', component: LsEditComponent },
      { path: 'setup', component: LsSetupComponent }
    ]
  },
  { path: 'apptconfirm', component: AppointmentConfirmComponent },
  //{ path: 'search-results', component: SearchResultsPageComponent },
  { path: 'edit-profile/:id', component: AgentSignupPageComponent },
  { path: 'agent-signup', component: AgentSignupPageComponent },
  { path: 'agent-signup/:id', component: AgentSignupPageComponent },
  { path: 'agent-signup-confirmation', component: AgentSignupConfirmationComponent },
  { path: 'schedule-confirmation', component: ScheduleConfirmationPageComponent },
  { path: 'qrcode-signup', component: QrcodeSignupComponent },
  { path: 'qrcode-rider', component: QrcodeRiderComponent },
  { path: 'qrcode-access-confirmation', component: QrcodeAccessConfirmationComponent },
  { path: 'agentqr', component: QrcodeAgentComponent },
  { path: 'access/:id', component: QrcodeMyagentComponent },
  { path: 'savedsearch/:id', component: SavedSearchesPageComponent },
  { path: 'savedsearch', component: SavedSearchesPageComponent },
  { path: 'promo/:promocode', component: PromoPageComponent },
  { path: 'promocert/:certificate', component: PromocertPageComponent },
  { path: 'feedback/:prospectId', component: FeedbackformComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
  // {
  //   path: '',
  //   redirectTo: '/home',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
