import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject, throwError } from 'rxjs';
import { IPropertyListingModel } from '../models/property-listing';
import { TokenCacheService } from './token-cache.service';
import { Contact } from '../models/contactus';
import { RouterStateSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { ISearchFiltersModel } from '../models/search-filters';
import { resolve } from 'url';
import { LightningData } from '../lightning-setup/lightning-setup.model';
import { ToastrService } from 'ngx-toastr';
import { NgxLoadingServiceService } from './ngx-loading-service.service'
@Injectable({
  providedIn: "root"
})
export class ProxyServiceService {
  // TODO: This json file will be replaced with endpoint from Carl

  readonly baseUrlPrefix = "https://ia9inc";
  readonly v2UrlPrefix = "https://ia9v2";
  readonly coreUrlPrefix = "https://ia9core";
  readonly urlSuffix = ".azurewebsites.net/api/";
  readonly lightningBaseUrl = "https://ia9lightning";
  readonly ENVIRONMENT_VALUE = "-dev";
  readonly PRODUCTION_VALUE = "";

  mainBaseUrl: string = "";
  mainCoreUrl: string = "";
  mainV2Url: string = "";
  mainResetUrl: string = "";
  personalProfileUrl: string = "";
  mainLightningUrl = "";

  mainBaseCode: string = "";
  mainCoreCode: string = "";
  mainV2Code: string = "";
  mainLightningCode = "";
  mainFeedbackCode = "";
  mainX_CLIENT_IDCode = "";

  readonly paymentUrl = "https://ia9payment.azurewebsites.net/api/";
  readonly openCageUrl = "https://api.opencagedata.com/geocode/v1/json?key=";
  readonly openCageKEY = "52d62777118b487fbe868eeedeaf6ff3";
  readonly HUBSPOTURL = "https://forms.hubspot.com/uploads/form/v2/5833180/c11e50e1-e10c-4799-b4b8-64be8df8a8cc";

  readonly coreUrlCODE = "TBXcXr/TFxalU9vKZaiagufE4M9hGp5PKQ32SLLeE2rm1IzmpKwqkQ==";
  readonly baseUrlCODE = "vB1isdKGkqy6RDInPNkSVN9bbJn33xa7HEUyEoExaqJLT584CAIiUA==";
  readonly v2UrlCODE = "gd5Cuq56CCNRr0luKeyPlhTzcAnDrh1IPW2CN697za3rDRbupTi1Lw==";
  readonly lightningCODE = "ucLrkFVooPmg4Z76c5hbsw/1fZrXV2EcClIr6MLrX0HvkHFgeHZV3A==";
  readonly feedbackCODE = "Xwem5hy68jBppLUSSG8frIRw7SaeRMFM/JjmA1wCbiiQoTGhSlJ0lg==";
  readonly X_CLIENT_IDCODE = "c606af7f-9da0-4360-b878-27dfa8a43238";
  readonly coreUrlCODEDEV = "PQfl6RyG62n176NdcZW2s9p8NYAKoFmoDp0rg/T4wThDl07TKIxvOw==";
  readonly baseUrlCODEDEV = "de4ybg4pah7W4bNXrDVSK0wMQ2JOKfJPMHmDsdTJqebCVvOLlAjUnw==";
  readonly v2UrlCODEDEV = "LZz3ox8YDY8GQ8nXH3d7/Ve6ZcBnG3ay0VH7H/hTHXdMltdm4y/rig==";
  readonly lightningCODEDEV = "yIcsZ9MioJPgcHQoCDUAaS6EyuG/4odgd7cWniq0tM4OfFxYR0favQ==";
  readonly feedbackCODEDEV = "RH5vN5b19h5gy1mD4HkaZJWVP1u/sHq8qKjtMIimafBI8nncBSGAWw==";
  readonly X_CLIENT_IDCODEDEV = "fee8cc43-afd8-4da8-a4e3-6639745c4428";
  private propertyListings = new BehaviorSubject<IPropertyListingModel[]>([]);
  propertySearchSpecialMsg = "";
  currentListings = this.propertyListings.asObservable();
  currentSearchValue = "";
  currentSearchMetadata = "";
  performingSearch = false;
  notificationMessage: string;
  getCurrentProp: IPropertyListingModel;
  readonly CONTACT_US_URL = "https://ia9contactus.azurewebsites.net/api/ContactUs?code=CXr1EeN6bDT2hNLYsEubnsj9iXGwi2uAHBfGEqlSTldH0729zakcKg==";
  readonly ENCODED_URL_PROD = "https%3A%2F%2Fjwt.ms";
  readonly ENCODED_URL_ENV = "https%3A%2F%2Fjwt.ms";
  readonly PASSWORD_RESET_PREFIX = "https://ia9b2c.b2clogin.com/IA9B2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_domii_reset_web&client_id=063ef5ba-fa4b-419f-914c-34c8f73816fa&nonce=defaultNonce&redirect_uri=";
  readonly PASSWORD_RESET_SUFFIX = "&scope=openid&response_type=id_token&prompt=login";

  readonly PROFILE_EDIT_PREFIX = "https://IA9B2C.b2clogin.com/IA9B2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_edit_profile&client_id=8870b782-a80e-47b1-9b0d-77576cdc2751&nonce=defaultNonce&redirect_uri=";
  readonly PROFILE_EDIT_SUFFIX = "&scope=openid&response_type=id_token&prompt=login";
  private lightningData = new BehaviorSubject<LightningData>(null);
  constructor(
    private http: HttpClient,
    private tokenCacheService: TokenCacheService,
    private loadingService: NgxLoadingServiceService,
    private toastr: ToastrService,
  ) {
    let environment = this.PRODUCTION_VALUE;
    this.mainBaseUrl = this.baseUrlPrefix + environment + this.urlSuffix;
    this.mainCoreUrl = this.coreUrlPrefix + environment + this.urlSuffix;
    this.mainV2Url = this.v2UrlPrefix + environment + this.urlSuffix;
    this.mainLightningUrl = this.lightningBaseUrl + environment + this.urlSuffix;

    this.mainBaseCode = environment === this.PRODUCTION_VALUE ? this.baseUrlCODE : this.baseUrlCODEDEV;
    this.mainCoreCode = environment === this.PRODUCTION_VALUE ? this.coreUrlCODE : this.coreUrlCODEDEV;
    this.mainV2Code = environment === this.PRODUCTION_VALUE ? this.v2UrlCODE : this.v2UrlCODEDEV;
    this.mainLightningCode = environment === this.PRODUCTION_VALUE ? this.lightningCODE : this.lightningCODEDEV;
    this.mainFeedbackCode = environment === this.PRODUCTION_VALUE ? this.feedbackCODE : this.feedbackCODEDEV;
    this.mainX_CLIENT_IDCode = environment === this.PRODUCTION_VALUE ? this.X_CLIENT_IDCODE : this.X_CLIENT_IDCODEDEV;
    this.mainResetUrl = environment === this.PRODUCTION_VALUE ? this.PASSWORD_RESET_PREFIX + this.ENCODED_URL_PROD + this.PASSWORD_RESET_SUFFIX : this.PASSWORD_RESET_PREFIX + this.ENCODED_URL_ENV + this.PASSWORD_RESET_SUFFIX;

    this.personalProfileUrl = environment === this.PRODUCTION_VALUE ? this.PROFILE_EDIT_PREFIX + this.ENCODED_URL_PROD + this.PROFILE_EDIT_SUFFIX : this.PROFILE_EDIT_PREFIX + this.ENCODED_URL_ENV + this.PROFILE_EDIT_SUFFIX;
  }

  validatePromoCode(promoCode: string, uniqueId: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainV2Url + "PromoCode/" + promoCode + "?uniqueId=" + uniqueId + "&code=" + this.mainV2Code
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  validateCertificate(certificate: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(
          this.mainV2Url + "PromoCert/" + certificate + "?code=" + this.mainV2Code
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  postPromoCode(promoCode: string): Observable<any> {
    const token = this.tokenCacheService.token;

    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);

    return Observable.create((observer: Observer<any>) => {
      this.http
        .post(
          this.mainCoreUrl + "promocode/" + promoCode + "?code=" + this.mainCoreCode,
          {},
          { headers }
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  /**
   * This function gets the endpoint data and maps it to PropertyListingModel
   */
  getPropertyListings(
    search: string,
    filterParams: string = "",
    addressMetadata: string = ""
  ): Observable<IPropertyListingModel[]> {
    this.currentSearchValue = this.currentSearchValue === "" ? search : search === "" ? this.currentSearchValue : search;

    this.currentSearchMetadata = this.currentSearchMetadata === "" ? addressMetadata : addressMetadata === "" ? this.currentSearchMetadata : addressMetadata;

    this.notificationMessage = "";
    this.performingSearch = true;
    const queryParams = this.currentSearchValue + "&limit=100" + filterParams + this.currentSearchMetadata;

    return Observable.create((observer: Observer<IPropertyListingModel[]>) => {
      const assembledUrl = this.mainBaseUrl + "RETS?code=" + this.mainBaseCode + "&q=" + queryParams;

      this.http.get(assembledUrl).subscribe(
        (res: any) => {
          let listings = res as IPropertyListingModel[];
          listings = this.processStreetMetadata(listings);
          this.propertyListings.next(listings);
          this.performingSearch = false;
        },
        error => {
          this.performingSearch = false;
          this.propertyListings.next([]);
          this.notificationMessage = "Invalid Search. Please try again.";
        }
      );
    });
  }

  getPropertyListingsMap(
    search: string,
    viewport: any,
    filterParams: string = '',
    addressMetadata: string = ''
  ): Observable<IPropertyListingModel[]> {
    this.currentSearchValue = this.currentSearchValue === '' ? search : search === '' ? this.currentSearchValue : search;
    this.currentSearchMetadata = this.currentSearchMetadata === '' ? addressMetadata : addressMetadata === '' ? this.currentSearchMetadata : addressMetadata;
    viewport = `&Points=${viewport.nW.lat},${viewport.nW.lng}&Points=${viewport.nE.lat},${viewport.nE.lng}&Points=${viewport.sE.lat},${viewport.sE.lng}&Points=${viewport.sW.lat},${viewport.sW.lng}`;

    this.notificationMessage = '';
    this.performingSearch = true;
    const cities = search ? '&cities=' + search : '';
    const queryParams = cities + viewport + '&limit=50' + filterParams + this.currentSearchMetadata;

    return Observable.create((observer: Observer<IPropertyListingModel[]>) => {
      const assembledUrl = this.mainBaseUrl + 'RETS?code=' + this.mainBaseCode + queryParams;

      this.http.get(assembledUrl).subscribe(
        (res: any) => {
          let listings = res as IPropertyListingModel[];
          listings = this.processStreetMetadata(listings);
          this.propertyListings.next(listings);
          this.performingSearch = false;
        },
        error => {
          this.performingSearch = false;
          this.propertyListings.next([]);
          this.notificationMessage = 'Invalid Search. Please try again.';
        }
      );
    });
  }

  // Getting a simple listing from backend
  // id: the listing id
  // listingIdType: the type of listing (RETS, MLS or regular Listing Id) / default = listingId
  getPropById$(
    id: string,
    listingIdType: string = "listingId"
  ): Observable<IPropertyListingModel> {
    return this.http.get<IPropertyListingModel>(
      this.mainBaseUrl + "RETS?code=" + this.mainBaseCode + "&q=" + id + "&idType=" + listingIdType
    );
  }

  registerAgent(agentPayload: any): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .post(
          this.mainCoreUrl + "AgentSignup?code=" + this.mainCoreCode,
          agentPayload,
          { headers }
        )
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next("success");
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  scheduleAppointment(schedulePayload: any): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .post(this.mainCoreUrl + "schedulerequest", schedulePayload, {
          headers
        })
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  scheduleRequestGuest(schedulePayload: any) {
    const payload = { ...schedulePayload, ...{ leadSource: "Web", leadType: 1, leadReason: "info" } }
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
    return Observable.create((observer: Observer<any>) => {
      this.http
        .post(this.mainV2Url + "ScheduleRequestGuest/?code=" + this.mainV2Code, payload, { headers })
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getSingleIA9Agent(agentPersonId: string): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainCoreUrl + "User/" + agentPersonId, { headers })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            const errorResponse = error;
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  updateUser(): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http.post(this.mainCoreUrl + "User", {}, { headers }).subscribe(
        (res: any) => {
          const r = res;
          observer.next("success");
          observer.complete();
        },
        error => {
          const errorResponse = error;
        }
      );
    });
  }

  getMlsAffiliates(): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")

    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainV2Url + "MlsAffiliates/?code=" + this.mainV2Code, {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            const errorResponse = error;
            observer.error(errorResponse);
            observer.complete();
          }
        );
    });
  }

  getIA9Agents(query?: string): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);

    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainV2Url + "membership/1?code=" + this.mainV2Code + (query ? `&query=${query}` : ''), {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            const errorResponse = error;
            observer.error(errorResponse);
            observer.complete();
          }
        );
    });
  }

  getRETSFilters(queryString: string = "") {
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(
          this.mainBaseUrl +
          "getRETSFilters?code=" +
          this.mainBaseCode +
          queryString
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            const errorResponse = error;
          }
        );
    });
  }

  getUserProfileInfo() {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);

    return Observable.create((observer: Observer<any>) => {
      this.http.get(this.mainCoreUrl + "User", { headers }).subscribe(
        (res: any) => {
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
          const errorResponse = error;
        }
      );
    });
  }

  getProfessionalMemberships(
    agentType: number,
    query: string = ""
  ): Observable<any[]> {
    return Observable.create((observer: Observer<any>) => {
      let formattedUrl = "MembershipType/" + (agentType != null ? agentType : "") + "?code=" + this.mainV2Code;
      formattedUrl += query !== "" ? "&query=" + query : "";

      this.http.get(this.mainV2Url + formattedUrl).subscribe(
        (res: any) => {
          observer.next(res);
          observer.complete();
        },
        error => {
          const errorResponse = error;
          observer.error(errorResponse);
          observer.complete();
        }
      );
    });
  }

  createPurchase(
    nonce: string,
    chargeAmount: number,
    agent: any,
    membershipValue: string
  ) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    const url = `${this.paymentUrl
      }/updatesubscription?code=SJ0NOfEtS9knM/oVmNkHWD1INeKF44NnmhoXLBzH8oIDhLWjeTZShQ==&firstName=${agent.agentName.split(" ")[0]
      }&lastName=${agent.agentName.split(" ")[1]}&email=${agent.agentEmail
      }&planId=${membershipValue}`;

    return Observable.create((observer: Observer<any>) => {
      this.http.post(url, { nonce, chargeAmount }, { headers }).subscribe(
        (res: any) => {
          observer.next(res);
          observer.complete();
        },
        error => {
          const errorResponse = error;
          observer.error(errorResponse);
          observer.complete();
        }
      );
    });
  }

  submitContactForm(contact: Contact): Observable<Contact> {
    return Observable.create((observer: Observer<any>) => {
      this.http.post(this.CONTACT_US_URL, contact).subscribe(
        (res: any) => {
          const r = res;
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  submitHubSpotForm(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      "Content-Length": payload.length
    });
    return Observable.create((observer: Observer<any>) => {
      this.http.post(this.HUBSPOTURL, payload, { headers }).subscribe(
        (res: any) => {
          const r = res;
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  // Help to get the lat and lng from an address
  getLatLngService(address: string) {
    return this.http
      .get(this.openCageUrl + this.openCageKEY + "&q=" + address)
      .pipe(
        map(data => data),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  getLatAndLng(
    property: IPropertyListingModel
  ): Observable<{ lat: number; lng: number }> {
    const address =
      property.address.full +
      " " +
      property.address.city +
      " " +
      property.address.state +
      " " +
      property.address.country;
    return Observable.create((observer: Observer<any>) => {
      this.getLatLngService(address).subscribe(
        (addressData: any) => {
          const latt = addressData.results["0"].geometry.lat;
          const long = addressData.results["0"].geometry.lng;
          observer.next({ lat: latt, lng: long });
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  getCityPolyPoints(city: string, state: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Referrer', 'https://secure.domii.io/search');
    return this.http.get(`https://nominatim.openstreetmap.org/search.php?q={${city}%2C+${state}}&polygon_geojson=1&format=json`, { headers });
  }

  getProfessionalTypes(): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainV2Url + "MembershipType?code=" + this.mainV2Code, {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getStates(): any[] {
    const states: any[] = [
      {
        name: "Alabama",
        value: "AL"
      },
      {
        name: "Alaska",
        value: "AK"
      },
      {
        name: "American Samoa",
        value: "AS"
      },
      {
        name: "Arizona",
        value: "AZ"
      },
      {
        name: "Arkansas",
        value: "AR"
      },
      {
        name: "California",
        value: "CA"
      },
      {
        name: "Colorado",
        value: "CO"
      },
      {
        name: "Connecticut",
        value: "CT"
      },
      {
        name: "Delaware",
        value: "DE"
      },
      {
        name: "District Of Columbia",
        value: "DC"
      },
      {
        name: "Federated States Of Micronesia",
        value: "FM"
      },
      {
        name: "Florida",
        value: "FL"
      },
      {
        name: "Georgia",
        value: "GA"
      },
      {
        name: "Guam",
        value: "GU"
      },
      {
        name: "Hawaii",
        value: "HI"
      },
      {
        name: "Idaho",
        value: "ID"
      },
      {
        name: "Illinois",
        value: "IL"
      },
      {
        name: "Indiana",
        value: "IN"
      },
      {
        name: "Iowa",
        value: "IA"
      },
      {
        name: "Kansas",
        value: "KS"
      },
      {
        name: "Kentucky",
        value: "KY"
      },
      {
        name: "Louisiana",
        value: "LA"
      },
      {
        name: "Maine",
        value: "ME"
      },
      {
        name: "Marshall Islands",
        value: "MH"
      },
      {
        name: "Maryland",
        value: "MD"
      },
      {
        name: "Massachusetts",
        value: "MA"
      },
      {
        name: "Michigan",
        value: "MI"
      },
      {
        name: "Minnesota",
        value: "MN"
      },
      {
        name: "Mississippi",
        value: "MS"
      },
      {
        name: "Missouri",
        value: "MO"
      },
      {
        name: "Montana",
        value: "MT"
      },
      {
        name: "Nebraska",
        value: "NE"
      },
      {
        name: "Nevada",
        value: "NV"
      },
      {
        name: "New Hampshire",
        value: "NH"
      },
      {
        name: "New Jersey",
        value: "NJ"
      },
      {
        name: "New Mexico",
        value: "NM"
      },
      {
        name: "New York",
        value: "NY"
      },
      {
        name: "North Carolina",
        value: "NC"
      },
      {
        name: "North Dakota",
        value: "ND"
      },
      {
        name: "Northern Mariana Islands",
        value: "MP"
      },
      {
        name: "Ohio",
        value: "OH"
      },
      {
        name: "Oklahoma",
        value: "OK"
      },
      {
        name: "Oregon",
        value: "OR"
      },
      {
        name: "Palau",
        value: "PW"
      },
      {
        name: "Pennsylvania",
        value: "PA"
      },
      {
        name: "Puerto Rico",
        value: "PR"
      },
      {
        name: "Rhode Island",
        value: "RI"
      },
      {
        name: "South Carolina",
        value: "SC"
      },
      {
        name: "South Dakota",
        value: "SD"
      },
      {
        name: "Tennessee",
        value: "TN"
      },
      {
        name: "Texas",
        value: "TX"
      },
      {
        name: "Utah",
        value: "UT"
      },
      {
        name: "Vermont",
        value: "VT"
      },
      {
        name: "Virgin Islands",
        value: "VI"
      },
      {
        name: "Virginia",
        value: "VA"
      },
      {
        name: "Washington",
        value: "WA"
      },
      {
        name: "West Virginia",
        value: "WV"
      },
      {
        name: "Wisconsin",
        value: "WI"
      },
      {
        name: "Wyoming",
        value: "WY"
      }
    ];
    return states;
  }

  associateBuyerWithAgent(
    uniqueAgentId: string,
    userLoggedIn: boolean,
    promoCode: string = ""
  ): Observable<any> {
    const token = this.tokenCacheService.token;
    const promoCodeQueryString =
      promoCode !== "" ? "&promocode=" + promoCode : "";
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);

    return Observable.create((observer: Observer<any>) => {
      if (userLoggedIn) {
        // if user logged in, make it a post
        this.http
          .post(
            this.mainCoreUrl +
            "MyAgent/" +
            uniqueAgentId +
            "?code=" +
            this.mainCoreCode +
            promoCodeQueryString,
            {},
            { headers }
          )
          .subscribe(
            (res: any) => {
              observer.next(res);
              observer.complete();
            },
            error => {
              observer.error(error);
              observer.complete();
            }
          );
      } else {
        // user not logged in, keep it open
        this.http
          .get(
            this.mainV2Url +
            "MyAgent/" +
            uniqueAgentId +
            "?code=" +
            this.mainV2Code
          )
          .subscribe(
            (res: any) => {
              observer.next(res);
              observer.complete();
            },
            error => {
              observer.error(error);
              observer.complete();
            }
          );
      }
    });
  }

  getAgentProfileFromUniqueId(uniqueAgentId: string) {
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(
          this.mainV2Url +
          "MyAgent/" +
          uniqueAgentId +
          "?code=" +
          this.mainV2Code
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getMemberships(professionalType?: any, mlsAffiliate?: string): Observable<any> {
    const selectedMlsAffiliate = mlsAffiliate !== undefined || null || "" ? "&mlsAffiliate=" + mlsAffiliate : "";
    const selectedProfessionalType = professionalType !== undefined || null || "" ? professionalType : "";
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainV2Url + "MembershipPlans/" + selectedProfessionalType + "?code=" + this.mainV2Code + selectedMlsAffiliate, {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getProfessionalProfileForEdit(): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainCoreUrl + "AgentProfile?code=" + this.mainCoreCode, {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  updateProfessionalProfile(payload: any): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .post(
          this.mainCoreUrl + "AgentProfile?code=" + this.mainCoreCode,
          payload,
          { headers }
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  processStreetMetadata(listings: IPropertyListingModel[]) {
    this.propertySearchSpecialMsg = "";

    let processListings = listings;
    if (this.currentSearchMetadata) {
      const searchMetadataTokens = this.currentSearchMetadata.split("&");
      searchMetadataTokens.forEach(t => {
        if (t !== "") {
          /* we could split it, for now i'll match it to street_address*/
          //const key = t.split('=')[0];
          //const value = t.split('=')[1];

          switch (t) {
            // I only care about this type of search for now
            case "searchType=street_address":
              // if we are searching for street address (a specific one)
              // I should be able to get an array, and the top item should be the closest or the actual match
              if (listings.length > 0) {
                // Carl's trick for matching the top value out of the specific address search:
                // split the string into pieces, and match the first piece of the address
                // if it matches, go ahead and show that single one, if not, just show the whole list
                let modifiedSearchValue = this.currentSearchValue.replace(
                  /-/g,
                  " "
                );
                modifiedSearchValue = modifiedSearchValue.replace(/,/g, "");
                const currentSearchTokens = modifiedSearchValue.split(" ");

                for (let listing of listings) {
                  let modifiedListingAddress = listing.address.full.replace(
                    /-/g,
                    " "
                  );
                  modifiedListingAddress = modifiedListingAddress.replace(
                    /,/g,
                    ""
                  );
                  const listingAddressTokens = modifiedListingAddress.split(
                    " "
                  );

                  // if the first token matches the first token (usually the house number), then go for the first one
                  if (
                    currentSearchTokens[0] === listingAddressTokens[0] &&
                    currentSearchTokens[1] === listingAddressTokens[1]
                  ) {
                    processListings = [listing];
                    break;
                  }
                }

                // if we didn't find one in particular, just assign them all to it again
                if (processListings.length !== 1) {
                  processListings = listings;
                  this.propertySearchSpecialMsg =
                    "Sorry, we were not able to show an exact match of your property, but here are some houses for sale near the address you selected.";
                }
              }
              break;
          }
        }
      });
    }

    return processListings;
  }

  saveSearch(searchPayload: ISearchFiltersModel): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .put(
          this.mainCoreUrl + "SavedSearch?code=" + this.mainCoreCode,
          [searchPayload],
          { headers }
        )
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  updateSavedSearch(searchPayload: ISearchFiltersModel): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .put(
          this.mainCoreUrl + "SavedSearch?code=" + this.mainCoreCode,
          [searchPayload],
          { headers }
        )
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next("success");
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getsavedSearches(): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .get(this.mainCoreUrl + "SavedSearch/?code=" + this.mainCoreCode, {
          headers
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  getSavedSearch(id: string): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http.get(this.mainCoreUrl + 'SavedSearchResults/' + id + '?code=' + this.mainCoreCode, {
        headers
      }).subscribe(
        (res: any) => {
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  deleteSavedSearch(id: string): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .delete(
          this.mainCoreUrl + "SavedSearch/" + id + "?code=" + this.mainCoreCode,
          { headers }
        )
        .subscribe(
          (res: any) => {
            const r = res;
            observer.next("success");
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  postHubspotForm(payload: any, isNEREN: boolean): Observable<any> {
    let formId = isNEREN ? '4edc0ae7-9872-4870-a8bd-94978cb9ab32' : '10859c40-b5cd-47c7-9b64-5359203583ab';
    console.log(`isNEREN: ${isNEREN}`);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return Observable.create((observer: Observer<any>) => {
      this.http.post(`https://api.hsforms.com/submissions/v3/integration/submit/5833180/${formId}`,
        payload, { headers }).subscribe((res: any) => {
          const r = res;
          observer.next('success');
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  };

  getAgentDetails(payload: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    this.loadingService.startLoading();
    return this.http.get(`${this.mainLightningUrl}lightning/validate?mid=${payload.mid}&aid=${payload.aid}&code=${this.mainLightningCode}`, { headers }).pipe(
      map(data => {
        this.loadingService.endLoading();
        return data;
      }),
      catchError(err => {
        this.loadingService.endLoading();
        return this.loadingService.handleError(err);
      })
    );
  };

  enrollAgentId(aid: any, code: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json").set("X_CLIENT_ID", this.mainX_CLIENT_IDCode);
    if (!aid || !code) {
      this.toastr.error("agentId or code required!");
    }
    this.loadingService.startLoading();
    return this.http.post(`${this.mainLightningUrl}registration/enroll/${aid}?code=${code}`, '', { headers })
      .pipe(
        map(data => {
          this.loadingService.endLoading();
          return data;
        }),
        catchError(err => {
          this.loadingService.endLoading();
          return this.loadingService.handleError(err);
        })
      );
  }


  registerLightningProperty(payload: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    this.loadingService.startLoading();

    return this.http.post(`${this.mainLightningUrl}lightning/setup?code=${this.mainLightningCode}`, payload, { headers })
      .pipe(
        map(data => {
          this.loadingService.endLoading();
          return data;
        }),
        catchError(err => {
          this.loadingService.endLoading();
          return this.loadingService.handleError(err);
        })
      );
  }
  getLightningData(): Observable<LightningData> {
    return this.lightningData.asObservable();
  }
  setLightningData(newData: LightningData) {
    this.lightningData.next(newData);
  }
  lightningValidate(propertyId: string, agentId: string, mlsId: string, code: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
    return this.http.get(`${this.mainLightningUrl}lightning/validate/?code=${code}&pid=${propertyId}&aid=${agentId}&mid=${mlsId}`,
      { headers });
  }

  getLightningPropertySchedule(mid: string, sd: string, ed: string): Promise<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
    return new Promise((resolve, reject) => {
      this.loadingService.startLoading();
      this.http.get(`${this.mainLightningUrl}lightning/setup/?code=${this.mainLightningCode}&pid=${mid}&sd=${sd}&ed=${ed}`,
        { headers }).subscribe(result => {
          this.loadingService.endLoading();
          resolve(result);
        }, error => {
          this.loadingService.endLoading();
          reject(error);
        });
    });
  }

  bookLightningShowing(payload: any): Promise<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return new Promise((resolve, reject) => {
      this.loadingService.startLoading();
      this.http.post(`${this.mainLightningUrl}lightning/calendar/book/?code=${this.mainLightningCode}`, payload, { headers })
        .subscribe(result => {
          this.loadingService.endLoading();
          resolve(result);
        }, error => {
          this.loadingService.endLoading();
          reject(error);
        });
    });
  }

  getFavorites(): Observable<any> {
    const token = this.tokenCacheService.token;
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return Observable.create((observer: Observer<any>) => {
      this.http.get(this.mainCoreUrl + "Favorites/" + "?code=" + this.mainCoreCode, { headers })
        .subscribe((res: any) => {
          const r = res;
          observer.next(res);
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  }

  postPromo(payload: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return Observable.create((observer: Observer<any>) => {
      this.http.post(this.mainV2Url + "promo/neren" + "?code=" + this.mainV2Code, payload, { headers })
        .subscribe((res: any) => {
          const r = res;
          observer.next(res);
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  }

  getApptConfirmation(cmd: string, eventId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return new Observable((observer: Observer<any>) => {
      this.http.get(this.mainV2Url + `${cmd}/event/${eventId}` + '?code=' + this.mainV2Code, { headers, responseType: 'text' })
        .subscribe((res: any) => {
          const r = res;
          observer.next(r);
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  }


  getFeedbackQuestions(prospectId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return new Observable((observer: Observer<any>) => {
      this.http.get(`${this.mainLightningUrl}lightning/card/${prospectId}?code=${this.mainFeedbackCode}`, { headers })
        .subscribe((res: any) => {
          const r = res;
          observer.next(r);
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  }

  postFeedback(prospectId, payload: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return Observable.create((observer: Observer<any>) => {
      this.http.post(`${this.mainLightningUrl}lightning/card/${prospectId}?code=${this.mainFeedbackCode}`, payload, { headers })
        .subscribe((res: any) => {
          const r = res;
          observer.next(res);
          observer.complete();
        },
          error => {
            observer.error(error);
            observer.complete();
          });
    });
  }

}

